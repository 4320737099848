:root {
  --title-line-height: 60px;
}

.home .slide {
  width: 100%;
  height: 100vh;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  align-items: center;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
}

.home .slide .content {
  width: 700px;
  color: #fff;
  margin-left: 100px;
  z-index: 3;
}
.home .slide .content .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(var(--title-line-height) * 4);
  margin-bottom: 20px;
  font-size: calc(var(--title-line-height) + 5px);
  font-weight: 800;
  -webkit-backface-visibility: hidden;
}

.home .slide .content .title > * {
  height: var(--title-line-height);
  text-shadow: 0 0 10px #000;
  -webkit-text-shadow: 0 0 10px #000;
}

.home .slide .content .description {
  width: 500px;
  min-height: 100px;
  font-size: 18px;
  line-height: 23px;
  text-shadow: 0 0 10px #000;
}

.home .slide .content .buttons {
  margin: 2em 0 0;
  height: 50px;
  display: inline-flex;
}
.home .slide .content .buttons > * {
  padding-right: 10px;
}
.home .slide .content .buttons .homedrop.postcode {
  margin: unset;
}

.background-1 {
  background-image: url('../../../assets/step1.jpg');
  box-shadow: inset 25vw 3.5vh 19em 6em rgba(0, 0, 0, 0.85),
    inset -2.5vw -3.5vh 19em 6em rgba(0, 0, 0, 0.85);
}

.background-2 {
  background-image: url('../../../assets/step2.jpg');
  box-shadow: inset 25vw 3.5vh 19em 6em rgba(0, 0, 0, 0.85),
    inset -2.5vw -3.5vh 19em 6em rgba(0, 0, 0, 0.85);
}

.background-3 {
  background-image: url('../../../assets/step3.jpg');
  box-shadow: inset 25vw 3.5vh 19em 6em rgba(0, 0, 0, 0.85),
    inset -2.5vw -3.5vh 19em 6em rgba(0, 0, 0, 0.85);
}

.background-4 {
  background-image: url('../../../assets/step4.jpg');
  box-shadow: inset 25vw 3.5vh 19em 6em rgba(0, 0, 0, 0.85),
    inset -2.5vw -3.5vh 19em 6em rgba(0, 0, 0, 0.85);
}

.background-5 {
  background-image: url('../../../assets/step5.jpg');
  box-shadow: inset 25vw 3.5vh 19em 6em rgba(0, 0, 0, 0.85),
    inset -2.5vw -3.5vh 19em 6em rgba(0, 0, 0, 0.85);
}

.background-6 {
  background-image: url('../../../assets/step6.jpg');
  box-shadow: inset 25vw 3.5vh 19em 6em rgba(0, 0, 0, 0.85),
    inset -2.5vw -3.5vh 19em 6em rgba(0, 0, 0, 0.85);
}

.home .slide .cooperation-logo img {
  margin-top: 5px;
  height: 60px;
}

@-webkit-keyframes light-up {
  0% {
    text-shadow: 0 0 8px #000;
  }
  50% {
    text-shadow: 0 0 2.5px #fff, 0 0 5px #fff, 0 0 7.5px #fff, 0 0 10px #9a39ef, 0 0 17.5px #9a39ef;
  }
  100% {
    text-shadow: 0 0 8px #000;
  }
}

@keyframes light-up {
  0% {
    text-shadow: 0 0 8px #000;
  }
  50% {
    text-shadow: 0 0 2.5px #fff, 0 0 5px #fff, 0 0 7.5px #fff, 0 0 10px #9a39ef, 0 0 17.5px #9a39ef;
  }
  100% {
    text-shadow: 0 0 8px #000;
  }
}

@media only screen and (max-width: 991px) {
  :root {
    --title-line-height: 50px;
  }
  .home .slide .content {
    margin: 20px 20px 20px 50px;
    width: 100%;
  }

  .home .slide .content .description {
    width: 100%;
    padding-right: 30%;
  }
}

@media only screen and (min-width: 766px) {
  /* Desktop only */
  .home .slide .content .title.title-video {
    font-size: calc(var(--title-line-height) - 7px);
  }
}

@media only screen and (max-width: 767px) {
  :root {
    --title-line-height: 40px;
  }

  .home .slide .content .title-video {
    height: unset;
    min-height: calc(var(--title-line-height) * 4);
    font-size: calc(var(--title-line-height) + 1px);
  }

  .home .slide .content {
    margin: 10px 10px 10px 40px;
    width: 100%;
  }

  .home .slide .content .title > * {
    margin-bottom: 10px;
  }

  .home .slide .content .description {
    width: 100%;
    padding-right: 0;
  }
  .home .ui.primary.button {
    margin-left: 0;
  }

  .home .slide .content .buttons {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .home .slide .content .buttons > * {
    padding: 0;
    margin-bottom: 10px;
  }

  .home .slide .cooperation-logo p {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .home .slide .cooperation-logo img {
    margin-top: 0;
    height: 40px;
  }
}

@media only screen and (max-width: 420px) {
  :root {
    --title-line-height: 30px;
  }

  .home .slide {
    align-items: flex-end;
    position: unset;
  }

  .home .slide .content {
    position: absolute;
    bottom: 4%;
    left: -24px;
    width: 91.5%;
  }

  .home .slide.slide-video .content {
    position: absolute;
    bottom: -15px;
    left: -24px;
    width: 91.5%;
    margin-right: 30px;
  }

  .home .slide.slide-video video {
    margin-bottom: 25vh;
  }

  .home .slide .content .title {
    height: unset;
    min-height: calc(var(--title-line-height) * 4);
    font-size: calc(var(--title-line-height) + 1px);
  }

  .home .slide .content .title > * {
    margin-bottom: 10px;
  }

  .home .slide .content .title.title-video {
    margin-bottom: 0;
  }

  .home .slide .content .description {
    font-size: 16px;
    width: 100%;
    padding-right: 0;
  }

  .home .slide .content .buttons .homedrop.postcode,
  .home .slide .content .buttons .fade.visible.transition {
    width: 80% !important;
  }
  .home .slide .content .buttons .fade.visible.transition > .button {
    width: 100% !important;
  }

  .background-1 {
    background-image: url('../../../assets/step1m.jpg');
    box-shadow: inset 2vw 1.5vh 12em 0em rgba(0, 0, 0, 0.85),
      inset -2vw -0.5vh 12em 0em rgba(0, 0, 0, 0.85);
  }
  .background-2 {
    background-image: url('../../../assets/step2m.jpg');
    box-shadow: inset 2vw 1.5vh 12em 0em rgba(0, 0, 0, 0.85),
      inset -2vw -0.5vh 12em 0em rgba(0, 0, 0, 0.85);
  }
  .background-3 {
    background-image: url('../../../assets/step3m.jpg');
    box-shadow: inset 2vw 1.5vh 12em 0em rgba(0, 0, 0, 0.85),
      inset -2vw -0.5vh 12em 0em rgba(0, 0, 0, 0.85);
  }
  .background-4 {
    background-image: url('../../../assets/step4m.jpg');
    box-shadow: inset 2vw 1.5vh 12em 0em rgba(0, 0, 0, 0.85),
      inset -2vw -0.5vh 12em 0em rgba(0, 0, 0, 0.85);
  }
  .background-5 {
    background-image: url('../../../assets/step5m.jpg');
    box-shadow: inset 2vw 1.5vh 12em 0em rgba(0, 0, 0, 0.85),
      inset -2vw -0.5vh 12em 0em rgba(0, 0, 0, 0.85);
  }
  .background-6 {
    background-image: url('../../../assets/step6m.jpg');
    box-shadow: inset 2vw 1.5vh 12em 0em rgba(0, 0, 0, 0.85),
      inset -2vw -0.5vh 12em 0em rgba(0, 0, 0, 0.85);
  }
}

@media only screen and (max-width: 320px) {
  .postcode-home-btn .ui.button.gradient {
    padding: 11px;
  }
}
/* HEIGHT MEDIA QUERIES */
@media only screen and (max-height: 640px) {
  .home .slide .content .title {
    margin-bottom: 0;
  }

  .home .slide.slide-video video {
    margin-bottom: 15vh;
  }
}

@media only screen and (max-width: 900px) and (max-height: 450px) {
  .home .slide .content .title > * {
    font-size: 30px;
    height: 0.85em;
    margin-bottom: 10px;
  }
  .home .slide .description p {
    font-size: 16px;
    line-height: 1.05em;
    margin-bottom: 10px;
  }
  .home .slide .content .buttons {
    margin: 5px 0 0;
  }
  .home .slide .content .buttons button {
    padding: 8px 20px;
    margin: 0 !important;
  }
}

.home .slide .ui.primary.button {
  margin: 0;
}
