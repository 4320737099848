.fullfibre .slide.light .title {
  color: var(--primaryColor);
}
.fullfibre .slide .images {
  width: calc(100% + 30px);
  display: flex;
}
.fullfibre .slide .images .house {
  padding: 0px 10px;
  text-align: center;
  font-weight: bold;
}

.fullfibre .slide .images .house img {
  width: 100%;
}

.fullfibre .slide .images .house.house-vx {
  margin-top: calc(100vw / 7);
  margin-left: calc(-100vw / 4.8);
}

/* change distance of houses gifs for extra large screens */
@media only screen and (min-width: 1855px) {
  .fullfibre .slide .images .house.house-vx {
    margin-left: calc(-100vw / 20);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullfibre .slide .images {
    width: 100%;
    display: block;
  }
  .fullfibre .slide .images .house img {
    width: 50%;
    float: left;
    display: inline;
    padding: 0 10px;
  }
  .fullfibre .slide .images .house.house-vx {
    margin-left: 0;
  }
  .fullfibre .slide .images .house.house-vx img {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .fullfibre .slide .images .house img {
    padding: 15px;
    max-width: 400px;
  }
  .fullfibre .slide .images {
    width: 100%;
    display: block;
  }
  .fullfibre .slide .images .house.house-vx {
    margin-top: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 420px) {
  .fullfibre .slide .images {
    margin-top: 25px;
  }
  .fullfibre .slide .images .house img {
    padding: 0px;
  }
  .fullfibre .slide .images .house.house-vx {
    margin-top: 5px;
  }
}
