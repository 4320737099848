.browser-message {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  position: relative;
  z-index: 1;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url('../../assets/step1.jpg');
  background: -o-linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url('../../assets/step1.jpg');
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url('../../assets/step1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0vw 0vh 6em 6em rgba(0, 0, 0, 0.85), inset 0vw 0vh 10em 10em rgba(0, 0, 0, 0.85);
  text-shadow: 0 0 10px #000;
  color: white;
}

@media only screen and (max-width: 420px) {
  .browser-message {
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
      url('../../assets/step1m.jpg');
    background: -o-linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
      url('../../assets/step1m.jpg');
    background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
      url('../../assets/step1m.jpg');
    box-shadow: inset 0vw 0vh 4em 4em rgba(0, 0, 0, 0.85), inset 0vw 0vh 0em 0em rgba(0, 0, 0, 0.85);
  }
}
