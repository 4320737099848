.circle-progress {
  position: absolute;
  width: 100%;
  z-index: 2;
  transition: opacity 1s ease 1s;
  filter: drop-shadow(rgb(154, 57, 239) 0px 1px 5px) drop-shadow(rgb(154, 57, 239) 0px -1px 5px);
}

.circle-progress circle.background,
.circle-progress circle.progress {
  fill: none;
}

.circle-progress circle.background {
  stroke: #979797;
  opacity: 0.2;
}

.circle-progress circle.progress {
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 2s ease-in-out;
}
