html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

.router-wrapper {
  height: inherit;
}
