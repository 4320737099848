.openaccess .slide .content {
  width: 50%;
}

.openaccess .service-providers .loading {
  position: relative;
  padding-top: 50vh;
}

.openaccess .service-providers .noservice {
  color: white;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 30vh;
}

.openaccess .slide1 {
  background: url(../../assets/openaccess_section1.jpg);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.openaccess .slide.dark.slide1 {
  box-shadow: inset 36vw -5vh 12em 12em rgba(0, 0, 0, 1),
    inset 27.5vw 6.5vh 19em 8em rgba(0, 0, 0, 1);
}

.openaccess .slide2 > :first-child {
  padding: 85px 10px 50px 100px;
  font-size: 16px;
  color: #fff;
}

.openaccess .slide2.dark {
  min-height: 100vh;
  background: url(../../assets/service_provider.svg);
  background-color: black;
  background-size: auto 2904px;
  background-position: -160px -1324px;
  background-repeat: no-repeat;
  box-shadow: inset 1vw 3.5vh 12em 6em rgba(0, 0, 0, 0.8),
    inset -2.5vw 2.5vh 12em 6em rgba(0, 0, 0, 0.8);
}

.openaccess .slide2 .tabs {
  display: flex;
}
.openaccess .slide2 .tabs .tab {
  cursor: pointer;
  line-height: 23px;
  margin-right: 65px;
  color: rgba(255, 255, 255, 0.9);
}
.openaccess .slide2 .tabs .tab.selected {
  cursor: default;
  font-weight: 500;
  border-bottom: solid #fff 2px;
}

.openaccess .slide2 .service-providers .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.openaccess .slide2 .service-providers .list {
  z-index: 1;
}
.openaccess .slide2 .service-providers .list > .service-provider {
  width: 375px;
  margin: 15px;
  padding: 20px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.openaccess .slide2 .service-providers .list > .service-provider .logo {
  height: 75px;
  text-align: center;
  margin: 10px 0px 20px 0px;
}
.openaccess .slide2 .service-providers .list > .service-provider .logo img {
  max-height: 75px;
}
.openaccess .slide2 .service-providers .list > .service-provider .description {
  max-height: 150px;
  overflow-y: scroll;
}
.openaccess .slide2 .service-providers .list > .service-provider .categories {
  display: flex;
  justify-content: space-evenly;
  padding: 30px 0px;
}
.openaccess .slide2 .service-providers .list > .service-provider .categories .category {
  display: flex;
  color: #767676;
  align-items: center;
  flex-direction: column;
}
.openaccess
  .slide2
  .service-providers
  .list
  > .service-provider
  .categories
  .category
  > :first-child {
  margin-bottom: 10px;
}
.openaccess .slide2 .service-providers .list > .service-provider .learn-more {
  color: gray;
  cursor: pointer;
  padding-top: 10px;
  text-align: center;
  font-style: italic;
}
.openaccess .slide2 .service-providers .list > .service-provider .url {
  padding-top: 10px;
  text-align: center;
}
.openaccess .slide2 .service-providers .list > .service-provider .url a {
  text-decoration: none;
}
.openaccess .slide2 .service-providers .list > .service-provider .triangle {
  width: 120px;
  height: 120px;
  background: var(--primaryColor);
  position: absolute;
  transform: rotate(-45deg);
}
.openaccess .slide2 .service-providers .list > .service-provider .triangle.top {
  top: -60px;
  left: -60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.openaccess .slide2 .service-providers .list > .service-provider .triangle.bottom {
  bottom: -60px;
  right: -60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.openaccess .down-chevron-button {
  width: 180px;
  height: 40px;
  position: absolute;
  bottom: 27px;
  left: calc(50% - 90px);
  z-index: 1;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px;
  background: hsla(0, 0%, 100%, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.openaccess .down-chevron-button:hover {
  background: hsla(0, 0%, 100%, 0.35);
}
.openaccess .down-chevron-button img {
  width: 18px;
}

@media only screen and (max-width: 991px) {
  .openaccess .slide2 > :first-child {
    padding: 30px;
  }
  .openaccess .slide2 .tabs {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .openaccess .slide2 .service-providers .list > .service-provider {
    width: 319px;
  }
}

@media only screen and (max-width: 420px) {
  .openaccess .slide1 {
    background: url(../../assets/openaccess_section1m.jpg);
  }
  .openaccess .slide.dark.slide1 {
    box-shadow: none;
  }
  .openaccess .slide2 > :first-child {
    padding: 50px 20px;
  }
  .openaccess .slide2 .tabs {
    padding-left: 0;
  }
  .openaccess .slide2 .service-providers {
    padding: 0;
  }
  .openaccess .down-chevron-button {
    display: none;
  }
}
