.wave-progress {
  position: absolute;
  bottom: 60px;
  width: 100%;
  height: 40px;
  z-index: 2;
  transition: opacity 1s ease 1s;
  filter: drop-shadow(rgb(154, 57, 239) 0px 1px 5px) drop-shadow(rgb(154, 57, 239) 0px -1px 5px);
}

.wave-progress path.background,
.wave-progress path.progress {
  fill: none;
}

.wave-progress path.background {
  stroke: #979797;
  opacity: 0.2;
}

.wave-progress path.progress {
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 2s ease-in-out;
}
