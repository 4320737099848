#app_wrapper {
  height: inherit;
}

/* All pages have a sticky top navigation bar.
   A margin top is needed to prevent content to be placed under the top bar.
*/
.page-content {
  min-height: calc(100vh - var(--topNavigationMenuHeight));
  margin-top: 66px;
}

/* Pages having a transparent top bar, do not need the margin
*/
.navigation.transparent ~ .page-content {
  min-height: 100vh;
  margin-top: 0px;
}
.overflow-hidden {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

/* Home page specific logo style
*/
.vertically-horizontally-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* SLIDE STYLE shared by pages like
  - FULL FIBRE
  - RESIDENTIAL
  - BUSINESS
  - ABOUT US
*/
div:not(.home) > .slide {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background-size: auto 100%;
  background-position: top right;
  background-repeat: no-repeat;
}
div:not(.home) > .slide:not(:first-child) {
  padding: 136px 0px 70px 0px;
}
div:not(.home) > .slide .content,
div:not(.home) > .slide > .grid .content {
  padding-left: 100px;
  z-index: 3;
}
div:not(.home) > .slide > .content {
  width: 50%;
}
div:not(.home) > .slide.dark {
  color: #fff;
  background-color: #000;
  box-shadow: inset 50vw 15vh 19em 6em rgba(0, 0, 0, 0.95),
    inset -2.5vw -3.5vh 19em 6em rgba(0, 0, 0, 0.85);
}
div:not(.home) > .slide.light {
  box-shadow: inset 50vw 0vh 19em 6em rgba(255, 255, 255, 1),
    inset -2.5vw -3.5vh 19em 6em rgba(255, 255, 255, 0.85);
}
div:not(.home) > .slide .content .title h2 {
  line-height: 45px;
  padding-bottom: 20px;
}
div:not(.home) > .slide.dark .content .title h2 {
  text-shadow: 0px 0px 10px #000;
}
div:not(.home) > .slide.light .content {
  text-shadow: 0px 0px 10px #fff;
}
div:not(.home) > .slide .content .description {
  width: 500px;
  min-height: 100px;
  font-size: 16px;
  line-height: 23px;
}
div:not(.home) > .slide .content .description .buttons {
  display: inline-flex;
}
div:not(.home) > .slide .content .description .buttons > * {
  padding-right: 10px;
}
div:not(.home) > .slide .content .description .buttons .homedrop.postcode {
  margin: unset;
}
div:not(.home) > .slide .content .description ul li {
  margin-left: -20px;
}

@media only screen and (max-width: 991px) {
  div:not(.home) > .slide,
  div:not(.home) > .slide:not(:first-child) {
    padding: 50px;
    display: flex;
    align-items: flex-end;
  }
  div:not(.home) > .slide.dark {
    box-shadow: inset 25vw 7vh 19em 6em rgba(0, 0, 0, 0.95),
      inset -2.5vw -3.5vh 19em 6em rgba(0, 0, 0, 0.95);
  }
  div:not(.home) > .slide.light {
    box-shadow: inset 25vw 7vh 19em 6em rgba(255, 255, 255, 1),
      inset -2.5vw -3.5vh 19em 6em rgba(255, 255, 255, 0.85);
  }
  div:not(.home) > .slide .content,
  div:not(.home) > .slide > .grid .content {
    width: 100%;
    padding: 0px;
  }
  div:not(.home) > .slide .content .title h1 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 5px;
  }
  div:not(.home) > .slide .content .description {
    width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  div:not(.home) > .slide,
  div:not(.home) > .slide:not(:first-child) {
    background-size: 100% auto;
  }

  div:not(.home) > .slide .content .description .buttons {
    display: flex;
    flex-direction: column;
  }
  div:not(.home) > .slide .content .description .buttons > * {
    padding: 0;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 550px) {
  div:not(.home) > .slide,
  div:not(.home) > .slide:not(:first-child) {
    padding: 81px 15px 15px 15px;
    background-size: 100% auto;
  }
  div:not(.home) > .slide.dark {
    box-shadow: none;
  }
  div:not(.home) > .slide.light {
    box-shadow: none;
  }
  /* div:not(.home) > .slide .content .title h1 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 5px;
  } */
  div:not(.home) > .slide .content .title h2 {
    font-size: 32px;
    line-height: 37px;
    padding-bottom: 20px;
  }
  div:not(.home) > .slide p {
    text-align: justify;
  }
}

/* Down Chevron button
*/
.down-chevron-button {
  width: 45px;
  height: 45px;
  position: absolute;
  bottom: 27px;
  left: calc(50% - 20px);
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  background: rgba(255, 255, 255, 0.3);
}
.down-chevron-button:hover {
  background: rgba(255, 255, 255, 0.25);
}

@media only screen and (max-width: 420px) {
  .down-chevron-button {
    left: unset;
    right: 20px;
    bottom: 5vh;
  }
}
