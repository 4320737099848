.dots-progress {
  position: absolute;
  right: 50px;
  z-index: 1000;
  vector-effect: non-scaling-stroke;
}

.dots-progress .dot {
  cursor: pointer;
}
